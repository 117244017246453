
import { toResult } from '@/utils/toResult'
import { ElMessage } from 'element-plus'
import { defineComponent, reactive, ref, toRefs, watchEffect } from 'vue'
// import { editAPI } from '../mock'
import { editAPI } from '../api'
export default defineComponent({
  name: 'message-dialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object,
      default: () => {}
    }
  },
  setup(props, { emit }) {
    // 当前组件状态
    const state = reactive({
      isShow: false,
      isLoading: false,
      title: '修改短信',
      formRef: ref(),
      formData: null,
      rules: {
        smsContent: {
          required: true,
          message: '请输入',
          trigger: 'blur'
        }
      }
    })

    // 监听
    watchEffect(() => {
      state.isShow = props.visible
      state.formData = {
        smsName: props.row.smsName,
        smsTag: props.row.smsTag,
        smsContent: props.row.smsContent
      }
    })

    /**
     * @description: 执行关闭
     * @return {void}
     */
    const handleClose = (): void => {
      state.isShow = false
      emit('update:visible')
    }

    /**
     * @description: 异步发送请求
     * @param {object} parmas 请求入参
     * @return {void} Promise<void>
     */
    const handleSubmit = async (params: object): Promise<void> => {
      state.isLoading = true
      const [err, data, msg] = await toResult(editAPI(params))
      state.isLoading = false
      if (err) return
      console.log(data)
      ElMessage.success(msg)
      emit('update:list')
      handleClose()
    }

    /**
     * @description: 异步提交数据校验
     * @param {*}
     * @return {void}
     */
    const handleValidate = (): void => {
      state.formRef.validate((valid: boolean): void => {
        if (valid) {
          const p = {
            smsConfigId: props.row.smsConfigId,
            smsContent: state.formData.smsContent
          }
          handleSubmit(p)
        }
      })
    }
    return {
      ...toRefs(state),
      handleClose,
      handleValidate
    }
  }
})
