/*
 * @Description:
 * @Author: yaoxiaoxiao
 * @Date: 2021-03-31 15:33:30
 * @LastEditors: yaoxiaoxiao
 * @LastEditTime: 2021-03-31 17:34:57
 */
import service from '@/utils/request'

// 主列表
const LIST_PATH = '/admin/smsConfig/getPageOfSmsConfig'
// 编辑
const EDIT_PATH = '/admin/smsConfig/editSmsConfig'
// 详情
const DETAIL_PATH = '/admin/smsConfig/getSmsConfigDetail'

/**
* @description: 列表分页查询
* @param {any} data
* @return {object}
*/
export const getListAPI = (data: any): object => {
  return service.request({
    url: LIST_PATH,
    method: 'post',
    data
  })
}

/**
* @description: 修改|编辑
* @param {any} data
* @return {object}
*/
export const editAPI = (data: any): object => {
  return service.request({
    url: EDIT_PATH,
    method: 'post',
    data
  })
}

/**
* @description: 获取详情
* @param {any} data
* @return {object}
*/
export const detailAPI = (data: any): object => {
  return service.request({
    url: DETAIL_PATH,
    method: 'post',
    data
  })
}
